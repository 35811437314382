<template>
    <div class="container">
        <!-- <img src="../../assets/banner/product-title.png" alt=""> -->
        <!-- <p class="font">产品文案</p> -->
        <!-- <p class="font30">完整的投资管理解决方案、领先的资产定价与风险量化、模型的检验与持续监控  </p> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    width:100%;
    height: 320px;
    background: url('../../assets/banner/product.png') no-repeat;
    background-size: cover;
    background-position: center center;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.font30{
    font-size: 30px;
    margin-top: 26px;
}
.font{
    width:288px;
    height:72px;
    font-size:72px;
    font-family:zcool-gdh,zcool;
    font-weight:normal;
    color:rgba(0,0,0,1);
    line-height:82px;
}
</style>