<template>
    <div class="page">
        <Part1 />
        <div class="mains">
        <div class="c-left">
            <div class="border"></div>
            <p class="fund">风险计量</p>
            <div class="rate">
            </div>
            <div style="border-bottom:solid 1px #ddd;padding:10px">
                <p class="font16"><img :src="iconimg" @click="changepic" v-show="subimg"><img :src="iconimg1" @click="changepic" v-show="!subimg">风险模型</p>
                <p class="txt" v-if="showselect">
                    领先的风险多因子模型可用于预测各类型资产和市场的波动以及相互作用关系。多种厚尾概率分布和动态波动率模型则能精确描述市场极端事件概率与波动变化。<br>我们的因子包括：股票风险因子（风格因子、行业因子等）；债券风险因子（利率期限结构因子、利差因子、隐含波动率因子等）；以及公募基金风险因子（净值因子、持仓因子、基金经理因子等）。
                </p>
            </div>
            <div style="border-bottom:solid 1px #ddd;padding:10px">
                <p class="font16"><img :src="iconimg" @click="changepic1" v-show="subimg1"><img :src="iconimg1" @click="changepic1" v-show="!subimg1">风险监控</p>
                <p class="txt" v-if="showfeng">
                    计量组合当前和过去一段时间内的波动、跟踪误差、VaR、ES、动态亏损概率以及厚尾风险；计算组合在市场风险、信用风险和流动性风险上所有风险因子和板块的风险暴露；支持自由地选择在资产类别、板块、因子、子基金、个券或自定义层级分解风险，并展示各成分风险贡献。
                </p>
            </div>
            <div style="border-bottom:solid 1px #ddd;padding:10px">
                <p class="font16"><img :src="iconimg" @click="changepic2" v-show="subimg2"><img :src="iconimg1" @click="changepic2" v-show="!subimg2">压力测试</p>
                <p class="txt" v-if="showyou">
                    针对资产类别、板块或风险因子，应用丰富的历史情景、预测情景、因子情景，或改变组合的风险暴露，计算组合在各情景下的损益与风险，并且根据情景发生概率计算组合的期望收益与风险试算。风险模型同样应用组合构建与组合优化。
                </p>
            </div>
        </div>
        <div class="c-right">
            <img src="../../assets/product/风险计量.png" alt="" style="width:474px;height:317px">
        </div>
    </div>
        <ApplyForm/>
    </div>
</template>

<script>
import Part1 from './part1'
import ApplyForm from '../../components/ApplyForm'

export default {
    components: { Part1, ApplyForm },
    data() {
        return {
            iconimg:require('../../assets/sub.png'),
            iconimg1:require('../../assets/plug.png'),
            iconimg2:require('../../assets/plug.png'),
            subimg:true,
            subimg1:false,
            subimg2:false,
            showselect:true,
            showfeng:false,
            showyou:false,
        }
    },
    methods:{
        changepic(){
            this.subimg=!this.subimg
            this.showselect=!this.showselect
        },
        changepic1(){
            this.subimg1=!this.subimg1
            this.showfeng=!this.showfeng
        },
        changepic2(){
            this.subimg2=!this.subimg2
            this.showyou=!this.showyou
        }
    }
}
</script>

<style lang="less" scoped>
.mains{
    width:1340px;
    height: 400px;
    margin:0 auto;
    // background:yellow;
    padding:79px 0 90px 0;
    .c-left{
        height:400px;
        // background:red;
        // width:15%;
        float:left;
        margin-left:5.4%;
        .rate{
            width:644px;
            // height:40px;
            // padding:16px;
            border-bottom:solid 1px #ddd;
        }
        .border{
            width:130px;
            height:8px;
            background:#2E5082;
        }
        .fund{
            // width:308px;
            height:40px;
            font-size:32px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(51,51,51,1);
            line-height:32px;
            margin:15px 0;
            text-align:left;
        }
        .select{
            width:658px;
            height:136px;
            padding:20px 0 0 10px;
            border-bottom:solid 1px #ddd;
        }
        .txt{
            width:658px;
            // height:80px;
            // float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            text-indent: 28px;
            text-align:left;
            // margin-bottom:74px;
            
        }
        .font16{
            color:#2E5082;
            font-size:16px;
            text-align:left;
            font-weight:600;
        }
        .mark{
                color:#2E5082;
                font-weight:bold;
            }
        .solve{
            // width:180px;
            height:20px;
            float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            // font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            margin-top:19px;
            padding:0;
            a{
                text-decoration: none;
                color:#476CCB;
            }
        }

    }
    .c-right{
        width:474px;
        height:317px;
        float:right;
        margin-right:70px;
    }
    
}
img{
    padding-right:5px;
    margin:0;
}
</style>